<template>
  <div>
    <Header title="" description="" />
    <div v-if="loading" class="my-12 text-center mx-auto">
      <i class="text-5xl text-primary bx bx-loader-circle bx-spin"></i>
    </div>
    <div
      v-else
      class="relative z-50 -top-24 md:-top-52 2xl:-top-60 -mb-24 md:-mb-48"
    >
      <div class="container mx-auto px-8 sm:px-16 md:px-20 lg:px-28">
        <div v-if="event.video_url && event.video_url != 'null'">
          <iframe style="height: 75vh" class="w-full" :src="event.video_url">
          </iframe>
        </div>
        <div
          v-else
          style="height: 75vh"
          class="
            lg:w-3/4
            mx-auto
            bg-white
            flex
            items-center
            justify-center
            shadow-md
          "
        >
          <img
            v-if="event.photos.length > 0"
            :src="'https://' + event.photos[0].path"
            alt=""
            loading="lazy"
            class="w-full h-full object-cover"
          />
          <img
            v-if="event.photos.length == 0"
            src="../assets/ImagePlaceholder.svg"
            alt=""
            class="object-cover"
            loading="lazy"
          />
        </div>
        <div class="mt-8 md:w-11/12 mx-auto">
          <span class="text-dateText">{{ event.event_date }}</span>
          <h1 class="text-footerBg text-2xl my-5 font-medium">
            {{ $i18n.locale == "EN" ? event.title_en : event.title_ar }}
          </h1>
          <p class="text-newsDescription text-lg">
            {{
              $i18n.locale == "EN" ? event.description_en : event.description_ar
            }}
          </p>
          <h5 v-if="event.photos.length > 0" class="text-dateText mt-12">
            {{ $t("events.image_from_event") }}
          </h5>
        </div>
      </div>
      <swiper
        v-if="event.photos.length > 0"
        ref="mySwiper"
        class="w-full outline-none my-5 focus:outline-none"
        :options="swiperOption"
      >
        <swiper-slide class="" v-for="img in event.photos" :key="img.id">
          <img
            alt=""
            loading="lazy"
            :src="'https://' + img.path"
            class="w-full min-h-72 max-h-96 object-cover"
          />
        </swiper-slide>
      </swiper>
      <div
        v-if="event.photos.length > 0"
        class="
          container
          mt-5
          mx-auto
          px-8
          sm:px-16
          md:px-20
          lg:px-28
          flex
          items-center
          justify-start
        "
      >
        <button
          @click="slidePrev"
          class="
            border border-CarusoleButton
            mx-3
            bg-white
            hover:border-btn
            focus:ring-2
            text-CarusoleButton
            hover:text-white
            p-3
            flex
            items-center
            justify-center
            text-2xl
            outline-none
            focus:outline-none
            hover:bg-btn
          "
        >
          <i class="bx bxs-chevron-right"></i>
        </button>
        <button
          @click="slideNext"
          class="
            border border-CarusoleButton
            mx-3
            bg-white
            hover:border-btn
            focus:ring-2
            text-CarusoleButton
            hover:text-white
            p-3
            flex
            items-center
            justify-center
            text-2xl
            outline-none
            focus:outline-none
            hover:bg-btn
          "
        >
          <i class="bx bxs-chevron-left"></i>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/layout/Header";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";

export default {
  name: "eventDetals",
  data() {
    return {
      event: null,
      URL: process.env.VUE_APP_URL,
      loading: true,
      swiperOption: {
        spaceBetween: 30,
        breakpoints: {
          1024: {
            slidesPerView: 2.5,
          },
          768: {
            slidesPerView: 2,
          },
          590: {
            slidesPerView: 2,
          },
          320: {
            slidesPerView: 1.25,
          },
        },
      },
    };
  },
  created() {
    if (!this.$route.params.id) {
      this.$router.push("/");
      return;
    }
  },
  mounted() {
    this.$store
      .dispatch("fetchEventDetails", this.$route.params.id)
      .then((res) => {
        this.event = res.data;
        this.loading = false;
      })
      .catch(() => {
        this.$router.push("/");
      });
  },
  methods: {
    slidePrev() {
      this.swiper.slidePrev();
    },
    slideNext() {
      this.swiper.slideNext();
    },
  },
  components: {
    Header,
    Swiper,
    SwiperSlide,
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  directives: {
    swiper: directive,
  },
};
</script>
